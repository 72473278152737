import TicketApproveStatusType from 'constants/ticket-approve-status-type'
import checkPermissionTicket from './check-permission'

const { default: TicketStatusType } = require('constants/ticket-status-type')
const { default: UserRoleLevel } = require('constants/user-role-level')

const abilities = {
  TicketRatingResult: {
    status: TicketStatusType.DONE,
    is_owner: true,
  },

  TicketRejectResult: [
    {
      status: TicketStatusType.PROCESSED,
      is_owner: true,
      status_approve: TicketApproveStatusType.NONEED,
    },
    {
      status: TicketStatusType.PROCESSED,
      is_owner: true,
      status_approve: TicketApproveStatusType.APPROVED,
    },
  ],

  TicketConfirmResult: [
    {
      status: TicketStatusType.PROCESSED,
      is_owner: true,
      status_approve: TicketApproveStatusType.NONEED,
    },
    {
      status: TicketStatusType.PROCESSED,
      is_owner: true,
      status_approve: TicketApproveStatusType.APPROVED,
    },
  ],

  TicketTakeover: {
    status: TicketStatusType.PENDING,
    is_assignee: true,
  },

  TicketRefuseToTakeover: {
    status: TicketStatusType.PENDING,
    is_assignee: true,
  },

  TicketContinueToTakeover: {
    status: TicketStatusType.REJECTED,
    is_assignee: true,
  },

  TicketSubmitResult: {
    status: TicketStatusType.PROCESSING,
    is_assignee: true,
  },

  TicketSubmitFinalResult: {
    status: TicketStatusType.PROCESSING,
    is_assignee: true,
  },

  TicketCreateSubTicket: [
    {
      status: TicketStatusType.PROCESSING,
      level: UserRoleLevel.SUPERADMIN,
      is_root_ticket: true,
    },
    {
      status: TicketStatusType.PROCESSING,
      level: UserRoleLevel.COORDINATOR,
      is_root_ticket: true,
    },
    {
      status: TicketStatusType.PROCESSING,
      is_assignee: true,
      is_root_ticket: true,
    },
  ],

  TicketClose: [
    {
      status: TicketStatusType.OPENING,
      is_coordinator: true,
      no_solution: true,
    },
    {
      status: TicketStatusType.OPENING,
      role: UserRoleLevel.SUPERADMIN,
      no_solution: true,
    },
    {
      status: TicketStatusType.OPENING,
      is_owner: false,
      no_solution: true,
    },
  ],

  TicketReOpen: [
    {
      status: TicketStatusType.CLOSED,
      is_owner: true,
    },
  ],

  TicketDelete: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
      no_solution: true,
    },
    {
      status: TicketStatusType.OPENING,
      is_owner: true,
      no_solution: true,
    },
  ],

  TicketEditCategory: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.OPENING,
      is_owner: true,
    },
    {
      status: TicketStatusType.OPENING,
      is_coordinator: true,
    },
  ],

  isAllowEditTag: [
    {
      status: TicketStatusType.PROCESSED,
      is_assignee: true,
    },
  ],

  TicketEditContent: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.OPENING,
      is_owner: true,
    },
  ],

  TicketEditStore: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.OPENING,
      is_owner: true,
    },
  ],

  TicketEditAssignee: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.OPENING,
      is_coordinator: true,
    },
    {
      status: TicketStatusType.PENDING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.PENDING,
      is_coordinator: true,
    },
    {
      status: TicketStatusType.PROCESSING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.PROCESSING,
      is_coordinator: true,
    },
  ],

  TicketEditDeadline: [
    {
      status: TicketStatusType.OPENING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.OPENING,
      is_coordinator: true,
    },
    {
      status: TicketStatusType.PENDING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.PENDING,
      is_coordinator: true,
    },
    {
      status: TicketStatusType.PROCESSING,
      level: UserRoleLevel.SUPERADMIN,
    },
    {
      status: TicketStatusType.PROCESSING,
      is_coordinator: true,
    },
    {
      status: TicketStatusType.PROCESSING,
      is_assignee: true,
    },
  ],

  TicketEditResult: [
    {
      status: TicketStatusType.PROCESSED,
      is_assignee: true,
      status_approve: TicketApproveStatusType.NONEED,
    },
  ],

  StoreDelete: {
    level: UserRoleLevel.SUPERADMIN,
  },
}

function ability(ability, data, user) {
  return checkPermissionTicket(ability, data, user)
}

Object.keys(abilities).forEach((key) => {
  ability[key] = abilities[key]
})

export default ability
